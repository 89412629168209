<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_334_15)">
  <path d="M24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12Z" fill="#00CF2E"/>
  <path d="M20.1309 11.4465C20.1309 8.46055 16.602 5.20312 11.9873 5.20312C7.49139 5.20312 3.84375 7.84978 3.84375 11.4465C3.84375 14.765 6.68721 17.5067 10.6877 17.9105C10.8096 17.9205 10.9276 17.9581 11.0328 18.0204C11.138 18.0827 11.2277 18.1681 11.2951 18.2702C11.3912 18.4214 11.443 18.5966 11.4444 18.7757C11.4351 19.1439 11.3942 19.5106 11.3223 19.8717C11.3112 19.9207 11.3139 19.9717 11.3301 20.0192C11.3463 20.0668 11.3753 20.1088 11.414 20.1408C11.4526 20.1728 11.4994 20.1935 11.5491 20.2005C11.5988 20.2075 11.6495 20.2006 11.6955 20.1805C13.6126 19.3322 20.1309 15.8441 20.1309 11.4465Z" fill="white"/>
  <path d="M9.00464 12.9394H7.78311V10.0891C7.78311 9.98115 7.74021 9.87759 7.66385 9.80123C7.58749 9.72487 7.48392 9.68197 7.37593 9.68197C7.26794 9.68197 7.16437 9.72487 7.08801 9.80123C7.01165 9.87759 6.96875 9.98115 6.96875 10.0891V13.3466C6.96875 13.4546 7.01165 13.5581 7.08801 13.6345C7.16437 13.7109 7.26794 13.7537 7.37593 13.7537H9.00464C9.11263 13.7537 9.2162 13.7109 9.29256 13.6345C9.36892 13.5581 9.41182 13.4546 9.41182 13.3466C9.41182 13.2386 9.36892 13.135 9.29256 13.0587C9.2162 12.9823 9.11263 12.9394 9.00464 12.9394Z" fill="#00CF2E"/>
  <path d="M10.0844 9.68197C9.97643 9.68197 9.87287 9.72487 9.79651 9.80123C9.72014 9.87759 9.67725 9.98115 9.67725 10.0891V13.3466C9.67725 13.4546 9.72014 13.5581 9.79651 13.6345C9.87287 13.7109 9.97643 13.7537 10.0844 13.7537C10.1924 13.7537 10.296 13.7109 10.3723 13.6345C10.4487 13.5581 10.4916 13.4546 10.4916 13.3466V10.0891C10.4916 9.98115 10.4487 9.87759 10.3723 9.80123C10.296 9.72487 10.1924 9.68197 10.0844 9.68197Z" fill="#00CF2E"/>
  <path d="M13.8889 9.68197C13.8355 9.68197 13.7825 9.6925 13.7331 9.71296C13.6837 9.73342 13.6388 9.76342 13.601 9.80123C13.5632 9.83904 13.5332 9.88392 13.5127 9.93333C13.4923 9.98273 13.4817 10.0357 13.4817 10.0891V12.125L11.7852 9.84484C11.7339 9.77647 11.6624 9.72597 11.5808 9.70049C11.4993 9.67501 11.4117 9.67584 11.3307 9.70286C11.2496 9.72989 11.1791 9.78174 11.1291 9.85106C11.0791 9.92039 11.0522 10.0037 11.0522 10.0891V13.3466C11.0695 13.4401 11.1191 13.5247 11.1922 13.5855C11.2653 13.6464 11.3575 13.6797 11.4526 13.6797C11.5478 13.6797 11.6399 13.6464 11.7131 13.5855C11.7862 13.5247 11.8357 13.4401 11.853 13.3466V11.3107L13.5496 13.5909C13.5887 13.6435 13.64 13.6859 13.6991 13.7143C13.7582 13.7426 13.8234 13.7562 13.8889 13.7537C13.9327 13.7536 13.9762 13.7467 14.0179 13.7334C14.099 13.7063 14.1695 13.6544 14.2195 13.5849C14.2694 13.5155 14.2962 13.4321 14.2961 13.3466V10.0891C14.2961 10.0357 14.2856 9.98273 14.2651 9.93333C14.2446 9.88392 14.2147 9.83904 14.1768 9.80123C14.139 9.76342 14.0941 9.73342 14.0447 9.71296C13.9953 9.6925 13.9424 9.68197 13.8889 9.68197Z" fill="#00CF2E"/>
  <path d="M16.8694 12.125C16.9229 12.125 16.9758 12.1145 17.0252 12.094C17.0746 12.0736 17.1195 12.0436 17.1573 12.0058C17.1951 11.968 17.2251 11.9231 17.2456 11.8737C17.266 11.8243 17.2766 11.7713 17.2766 11.7179C17.2766 11.6644 17.266 11.6114 17.2456 11.562C17.2251 11.5126 17.1951 11.4677 17.1573 11.4299C17.1195 11.3921 17.0746 11.3621 17.0252 11.3417C16.9758 11.3212 16.9229 11.3107 16.8694 11.3107H15.6479V10.4963H16.8694C16.9774 10.4963 17.0809 10.4534 17.1573 10.3771C17.2337 10.3007 17.2766 10.1971 17.2766 10.0891C17.2766 9.98115 17.2337 9.87759 17.1573 9.80123C17.0809 9.72487 16.9774 9.68197 16.8694 9.68197H15.2407C15.1327 9.68197 15.0291 9.72487 14.9528 9.80123C14.8764 9.87759 14.8335 9.98115 14.8335 10.0891V13.3466C14.8335 13.4546 14.8764 13.5581 14.9528 13.6345C15.0291 13.7109 15.1327 13.7537 15.2407 13.7537H16.8694C16.9774 13.7537 17.0809 13.7109 17.1573 13.6345C17.2337 13.5581 17.2766 13.4546 17.2766 13.3466C17.2766 13.2386 17.2337 13.135 17.1573 13.0587C17.0809 12.9823 16.9774 12.9394 16.8694 12.9394H15.6479V12.125H16.8694Z" fill="#00CF2E"/>
  </g>
  <defs>
  <clipPath id="clip0_334_15">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
