<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_334_24)">
  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_334_24)"/>
  <path d="M17.3608 8.16664C15.609 6.85399 13.9306 6.89088 13.9306 6.89088L13.759 7.08713C15.8417 7.71276 16.8095 8.63277 16.8095 8.63277C13.8298 6.99008 10.0345 7.00186 6.93522 8.63277C6.93522 8.63277 7.93978 7.66362 10.145 7.03799L10.0226 6.89073C10.0226 6.89073 8.35637 6.85399 6.59232 8.16649C6.59232 8.16649 4.82812 11.3436 4.82812 15.257C4.82812 15.257 5.85729 17.0235 8.56474 17.1094C8.56474 17.1094 9.01802 16.5696 9.38553 16.1035C7.82971 15.6374 7.24159 14.6682 7.24159 14.6682C8.21908 15.2807 9.20743 15.664 10.4392 15.9072C12.4431 16.3203 14.9357 15.8956 16.7975 14.6682C16.7975 14.6682 16.1849 15.6619 14.58 16.1157C14.9475 16.5696 15.3885 17.0971 15.3885 17.0971C18.096 17.0113 19.125 15.2447 19.125 15.2571C19.1248 11.3438 17.3608 8.16664 17.3608 8.16664ZM9.69174 14.1162C9.00563 14.1162 8.44213 13.5151 8.44213 12.7668C8.49227 10.9747 10.9037 10.9802 10.9414 12.7668C10.9414 13.5151 10.3899 14.1162 9.69174 14.1162ZM14.1632 14.1162C13.4771 14.1162 12.9136 13.5151 12.9136 12.7668C12.9687 10.9782 15.35 10.9775 15.4128 12.7668C15.4128 13.5151 14.8616 14.1162 14.1632 14.1162Z" fill="white"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_334_24" x1="12" y1="0" x2="12" y2="23.9124" gradientUnits="userSpaceOnUse">
  <stop stop-color="#9E9DF1"/>
  <stop offset="1" stop-color="#6665D2"/>
  </linearGradient>
  <clipPath id="clip0_334_24">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
