<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_333_2)">
  <mask id="mask0_333_2" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <path d="M24 0H0V24H24V0Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_333_2)">
  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="url(#paint0_linear_333_2)"/>
  </g>
  <path d="M7 5.08235H8.98333L12.1333 11.7529L15.2833 5H17.2667L12.9111 13.4V19H11.3556V13.4L7 5.08235Z" fill="white"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_333_2" x1="12" y1="0" x2="12" y2="23.9124" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FF6600"/>
  <stop offset="1" stop-color="#FF6600"/>
  </linearGradient>
  <clipPath id="clip0_333_2">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
