<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1_26)">
  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#4E71A8"/>
  <path d="M14.95 9.75H12.95V8.45C12.95 7.95 13.3 7.85 13.5 7.85C13.75 7.85 14.9 7.85 14.9 7.85V5.65H12.95C10.75 5.65 10.3 7.3 10.3 8.3V9.75H9.05V12H10.3C10.3 14.9 10.3 18.35 10.3 18.35H12.95C12.95 18.35 12.95 14.85 12.95 12H14.75L14.95 9.75Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_1_26">
  <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
