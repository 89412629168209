<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_18)">
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#1CB7EB"/>
    <path d="M18.4 7.7C17.95 7.95 17.4 8.1 16.9 8.15C17.45 7.8 17.85 7.25 18.05 6.6C17.55 6.9 17 7.15 16.35 7.3C15.85 6.75 15.2 6.4 14.45 6.4C13 6.4 11.8 7.65 11.8 9.25C11.8 9.45 11.8 9.7 11.85 9.9C9.65001 9.8 7.70001 8.65 6.40001 6.95C6.15001 7.35 6.05001 7.85 6.05001 8.4C6.05001 9.4 6.50001 10.25 7.20001 10.75C6.75001 10.75 6.35001 10.6 6.00001 10.4V10.45C6.00001 11.8 6.90001 12.95 8.10001 13.25C7.90001 13.3 7.65001 13.35 7.40001 13.35C7.25001 13.35 7.05001 13.35 6.90001 13.3C7.25001 14.45 8.20001 15.25 9.35001 15.25C8.45001 16 7.30001 16.45 6.10001 16.45C5.90001 16.45 5.70001 16.45 5.45001 16.4C6.60001 17.2 8.00001 17.7 9.50001 17.7C14.35 17.7 17 13.4 17 9.65C17 9.55 17 9.4 17 9.3C17.6 8.8 18.05 8.3 18.4 7.7Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_1_18">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</template>
